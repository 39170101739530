@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;700;900&family=Roboto:wght@400;700&display=swap");
@layer components {
  .entry_image--dealer {
    @apply flex justify-center mt-4 ml-4 bg-white rounded-lg object-contain h-[60px] w-[181px];
  }
}

body {
  margin: 0;
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.scrollbarHideClass::-webkit-scrollbar {
  display: none;
}

.customScrollBar::-webkit-scrollbar {
  width: 8px;
}

.customScrollBar::-webkit-scrollbar-track {
  background: transparent;
}

.customScrollBar::-webkit-scrollbar-thumb {
  background: #298fc2;
  width: 8px;
  border-radius: 10px;
}

.image-input_center {
  background: url(assets/images/center-pin.png) no-repeat scroll 7px 16px;
  padding-left: 30px;
  background-color: white;
}

.image-input_search {
  background: url(assets/images/search.svg) no-repeat scroll 7px 16px;
  padding-left: 30px;
  background-color: white;
}

.image-input_search_bar {
  background: url(assets/images/search.svg) no-repeat scroll 7px 12px;
  padding-left: 30px;
  background-color: white;
}

.image-input_radius {
  background: url(assets/images/radius-pin.png) no-repeat scroll 7px 16px;
  padding-left: 35px;
  background-color: white;
}

.login_input--user {
  background: url(assets/images/login_input-user.svg) no-repeat scroll 16px 16px;
  padding-left: 65px;
  background-color: white;
}
.login_input--password {
  background: url(assets/images/login_input-password.svg) no-repeat scroll 16px
    16px;
  padding-left: 65px;
  background-color: white;
}

.bell_button--notification {
  padding: 0px 18px;
  position: relative;
  display: inline-block;
}

.bell_button--notification .badge_icon--alerts {
  position: absolute;
  top: -2px;
  right: 5px;
  padding: 2px 7px;
  border-radius: 50%;
  background: #298fc2;
  color: black;
}

.hide-arrow[type="number"]::-webkit-inner-spin-button,
.hide-arrow[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
